import React, { useState, useEffect } from "react";
import { TopHeading } from "../components/common"
import { ClimbingArea } from "../types"
import Daily from "../components/climbingAreaDaily";
import Hourly from "../components/climbingAreaHourly";
import fetchAreaDetail from "../api/areaDetail";

interface ClimbingAreaPageProps { areaId: string }
const ClimbingAreaPage = (props: ClimbingAreaPageProps) => {

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<ClimbingArea | null>(null);

  const areaId = props.areaId;

  useEffect(() => {
    fetchAreaDetail(areaId)
    .then(
      (result) => {
        setIsLoaded(true);
        setResult(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    ); 
  }, [areaId]);

  useEffect(() => {
    document.title = result?.name + " - Daily Climbing Forecast" ?? "ClimbingWeather.com";
  }, [result]);

  if (error) {
    return <div>Error: (error.message)</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else if (result === null) {
    return <div>None</div>;
  } else {
    let area = result!
    return (
      <React.Fragment>
        <TopHeading>
          {area.name} | {area.adminAreaName}
        </TopHeading>
          <Hourly area={area} />
          <Daily area={area} />
          {/*<StaticMap latitude={area.latitude} longitude={area.longitude} />*/}
      </React.Fragment>
    );
  }

};

export default ClimbingAreaPage;