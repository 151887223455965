import { ClimbingArea } from "../types";
import { apiDomain } from "../utils/climbingAreaUtils";

async function featuredArea(srch: string = ""): Promise<ClimbingArea> {

    const baseUrl = new URL("https://" + apiDomain());
    const url = new URL('/area/detail/featured', baseUrl);
    url.searchParams.append('days', '3');

    const response = await fetch(url.toString());
    const area: ClimbingArea = await response.json();
    if (response.ok && area) {
        return area;
    } else {
        return Promise.reject(new Error(response.statusText));
    }
}

export default featuredArea;