import React from 'react';
import { Link } from 'react-router-dom';
import Search from "./search";

interface HeaderSectionProps { }
function HeaderSection(props: HeaderSectionProps) {
  return (
    <div className="headerwrap">
      <div className="header">
          <div className="headerInner">
        <div id="headerIcon">
            <Link to="/">
              <img src="https://static.climbingweather.com/img/icon_bare.png" alt="ClimbingWeather.com" className="responsive" style={{ height: "30px"}}/>
            </Link>
        </div>
        <Search />
        <div id="headerViewAll"><Link to="/forecast-by-state">Browse Areas</Link></div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;