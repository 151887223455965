import React from 'react'
import { AdminArea } from '../types';
import AdminAreaSection from './adminAreaSection';

interface AdminAreaListingProps {
    adminAreas: AdminArea[]
    frontPage: boolean
}

const AdminAreaListing = (props: AdminAreaListingProps) => {
    return (
        <div>
            {props.adminAreas.map((adminArea) => (
                <AdminAreaSection key={adminArea.name} adminArea={adminArea} format={props.frontPage ? "list" : "block"} />
            ))}
        </div>
    )
};

export default AdminAreaListing;
