import React, { useEffect } from 'react';

import TopHeading from "../components/topHeading";
import ContentHeading from "../components/contentHeading";
import ClimbingAreasByState from "../components/climbingAreasByState";

const HomeScreen = () => {

    useEffect(() => {
      document.title = "ClimbingWeather.com - Rock Climbing Weather Forecasts";
    }, []);
    return (
        <React.Fragment>
          <TopHeading>What's New</TopHeading>
          <div className="whatnew">We've re-designed the website! Although it might be a bit subtle to some of you, the new site is faster
            and displays better on mobile devices!</div>
          <ContentHeading>Popular US Climbing Areas</ContentHeading>
          <div className="box">
              <ClimbingAreasByState frontPage={true} />
          </div>
        </React.Fragment>
      );
};

export default HomeScreen;