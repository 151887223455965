import React from 'react';

interface ContentWrapProps { children?: React.ReactNode }
const ContentWrap = (props: ContentWrapProps) => {
  return (
    <div className="contentwrap">
        {props.children}
    </div>
  );
}

interface ContentMainProps { children?: React.ReactNode }
const ContentMain = (props: ContentMainProps) => {
  return (
    <div className="content">
        {props.children}
    </div>
  );
};

interface ContentSideProps { children?: React.ReactNode }
const ContentSide = (props: ContentSideProps) => {
  return (
    <div className="side">
        {props.children}
    </div>
  );
};

interface Props { main: React.ReactNode, side: React.ReactNode }

const TwoColumn = (props: Props) => {
    return (
        <ContentWrap>
            <ContentMain>
                {props.main}
            </ContentMain>
            <ContentSide>
                {props.side}
            </ContentSide>
      </ContentWrap>
    )
}

export default TwoColumn;