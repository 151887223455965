import React from 'react';
import {
  Switch,
  Route,
  useParams
} from "react-router-dom"

import './App.css';

import ForecastByStateAreas from "./screens/allAreas"
import FeaturedArea from './components/featuredArea';
import ClimbingAreaPage from "./screens/areaDetail";
import HeaderSection from "./components/header";
import FooterSection from "./components/footer";
import HomeScreen from "./screens/home";
import OneColumn from "./components/oneColumn";
import TwoColumn from "./components/twoColumn";

interface AppProps { }
function App(props: AppProps) {
  return (
    <React.Fragment>
        <HeaderSection></HeaderSection>
      <Switch>
        {/* Area detail */}
        <Route path="/area/:areaId/detail">
          <TwoColumn main={<AreaDetail />} side={<FrontSide />} />
        </Route>

        {/* Browser Areas */}
        <Route path="/forecast-by-state/:stateCode?">
          <OneColumn>
            <ForecastByState />
          </OneColumn>
        </Route>

        {/* Home Page and catch-all */}
        <Route path="/">
          <TwoColumn main={<HomeScreen />} side={<FrontSide />} />
        </Route>

      </Switch>
        <FooterSection></FooterSection>
    </React.Fragment>
  );
}

interface ForecastByStateParams { stateCode?: string }
function ForecastByState() {
  let { stateCode } = useParams<ForecastByStateParams>();
  return <ForecastByStateAreas code={stateCode} />
}

interface AreaDetailParams { areaId: string }
function AreaDetail() {
  let { areaId } = useParams<AreaDetailParams>();
  return <ClimbingAreaPage areaId={areaId} />;
}

interface FrontSideProps { }

function FrontSide(props: FrontSideProps) {
  return (
    <React.Fragment>
      <div className="rightBoxTitleTop">Where you should be climbing now</div>
      <div className="rightBoxContent">
          <FeaturedArea></FeaturedArea>
          <div style={{ clear: "both" }}></div>
      </div>
    </React.Fragment>
  );
}

export default App;
