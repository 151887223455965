import React from "react";
import h1corner from "../img/h1corner.png";

interface Props { children: React.ReactNode };
const TopHeading = (props: Props) => {
    return (
        <React.Fragment>
          <h1 className="top">{props.children}</h1>
          <div className="h1corner"><img src={h1corner} width="17" height="13" alt=""/></div>
         </React.Fragment>

    )
};

export default TopHeading;