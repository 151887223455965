
import React from 'react';

interface Props { children: React.ReactNode }

interface ContentWrapFullProps { children?: React.ReactNode }
const ContentWrapFull = (props: ContentWrapFullProps) => {
  return (
    <div className="contentwrapfull">
        {props.children}
    </div>
  );
};

interface ContentFullProps { children?: React.ReactNode }
const ContentFull = (props: ContentFullProps) => {
  return (
    <div className="contentFull">
      {props.children}
    </div>
  );
};

const OneColumn = (props: Props) => {
    return (
        <ContentWrapFull>
            <ContentFull>
                {props.children}
            </ContentFull>
        </ContentWrapFull>
    );
};

export default OneColumn;