import React from "react";
import { ForecastDataPoint } from "../types";

export const degreeCharacter = String.fromCharCode(176);
export enum Constants {
    MissingValue = "--"
}

interface WxSymbol {
  image: string,
  isPrecip: boolean,
  description?: string
}

const bknSymbol: WxSymbol = { image: "cloudy3", isPrecip: false }
const nbknSymbol: WxSymbol = { image: "cloudy3_night", isPrecip: false }
const skcSymbol: WxSymbol = { image: "sunny", isPrecip: false, description: "Fair | Clear | Fair with Haze | Clear with Haze | Fair and Breezy | Clear and Breezy" }
const nskcSymbol: WxSymbol = { image: "sunny_night", isPrecip: false }
const fewSymbol: WxSymbol = { image: "cloudy1", isPrecip: false, description: "A Few Clouds | A Few Clouds with Haze | A Few Clouds and Breezy" }
const nfewSymbol: WxSymbol = { image: "cloudy1_night", isPrecip: false }
const sctSymbol: WxSymbol = { image: "cloudy2", isPrecip: false, description: "Partly Cloudy | Partly Cloudy with Haze | Partly Cloudy and Breezy" }
const nsctSymbol: WxSymbol = { image: "cloudy2_night", isPrecip: false }
const ovcSymbol: WxSymbol = { image: "overcast", isPrecip: false, description: "Overcast | Overcast with Haze | Overcast and Breezy" }
const novcSymbol: WxSymbol = { image: "overcast", isPrecip: false }
const fgSymbol: WxSymbol = { image: "fog", isPrecip: false, description: "Fog/Mist | Fog | Freezing Fog | Shallow Fog | Partial Fog | Patches of Fog | Fog in Vicinity | Freezing Fog in Vicinity | Shallow Fog in Vicinity | Partial Fog in Vicinity | Patches of Fog in Vicinity | Showers in Vicinity Fog | Light Freezing Fog | Heavy Freezing Fog" }
const nfgSymbol: WxSymbol = { image: "fog_night", isPrecip: false }
const smokeSymbol: WxSymbol = { image: "fog", isPrecip: false, description: "Smoke" }
const fzraSymbol: WxSymbol = { image: "sleet", isPrecip: true, description: "Freezing Rain | Freezing Drizzle | Light Freezing Rain | Light Freezing Drizzle | Heavy Freezing Rain | Heavy Freezing Drizzle | Freezing Rain in Vicinity | Freezing Drizzle in Vicinity" }
const ipSymbol: WxSymbol = { image: "hail", isPrecip: true, description: "Ice Pellets | Light Ice Pellets | Heavy Ice Pellets | Ice Pellets in Vicinity | Showers Ice Pellets | Thunderstorm Ice Pellets | Ice Crystals | Hail | Small Hail/Snow Pellets | Light Small Hail/Snow Pellets | Heavy small Hail/Snow Pellets | Showers Hail | Hail Showers" }
const mixSymbol: WxSymbol = { image: "sleet", isPrecip: true, description: "Freezing Rain Snow | Light Freezing Rain Snow | Heavy Freezing Rain Snow | Freezing Drizzle Snow | Light Freezing Drizzle Snow | Heavy Freezing Drizzle Snow | Snow Freezing Rain | Light Snow Freezing Rain | Heavy Snow Freezing Rain | Snow Freezing Drizzle | Light Snow Freezing Drizzle | Heavy Snow Freezing Drizzle" }
const nmixSymbol: WxSymbol = { image: "sleet", isPrecip: true }
const raipSymbol: WxSymbol = { image: "sleet", isPrecip: true, description: "Rain Ice Pellets | Light Rain Ice Pellets | Heavy Rain Ice Pellets | Drizzle Ice Pellets | Light Drizzle Ice Pellets | Heavy Drizzle Ice Pellets | Ice Pellets Rain | Light Ice Pellets Rain | Heavy Ice Pellets Rain | Ice Pellets Drizzle | Light Ice Pellets Drizzle | Heavy Ice Pellets Drizzle" }
const rasnSymbol: WxSymbol = { image: "sleet", isPrecip: true, description: "Rain Snow | Light Rain Snow | Heavy Rain Snow | Snow Rain | Light Snow Rain | Heavy Snow Rain | Drizzle Snow | Light Drizzle Snow | Heavy Drizzle Snow | Snow Drizzle | Light Snow Drizzle | Heavy Drizzle Snow" }
const nrasnSymbol: WxSymbol = { image: "sleet", isPrecip: true }
const shraSymbol: WxSymbol = { image: "shower3", isPrecip: true, description: "Rain Showers | Light Rain Showers | Light Rain and Breezy | Heavy Rain Showers | Rain Showers in Vicinity | Light Showers Rain | Heavy Showers Rain | Showers Rain | Showers Rain in Vicinity | Rain Showers Fog/Mist | Light Rain Showers Fog/Mist | Heavy Rain Showers Fog/Mist | Rain Showers in Vicinity Fog/Mist | Light Showers Rain Fog/Mist | Heavy Showers Rain Fog/Mist | Showers Rain Fog/Mist | Showers Rain in Vicinity Fog/Mist" }
const scttsraSymbol: WxSymbol = { image: "tstorm2", isPrecip: true, description: "?? Scattered thunderstorms" }
const nscttsraSymbol: WxSymbol = { image: "tstorm2_night", isPrecip: true, description: "?? Scattered thunderstorms" }
const tsraSymbol: WxSymbol = { image: "tstorm3", isPrecip: true, description: "Thunderstorm | Thunderstorm Rain | Light Thunderstorm Rain | Heavy Thunderstorm Rain | Thunderstorm Rain Fog/Mist | Light Thunderstorm Rain Fog/Mist | Heavy Thunderstorm Rain Fog and Windy | Heavy Thunderstorm Rain Fog/Mist | Thunderstorm Showers in Vicinity | Light Thunderstorm Rain Haze | Heavy Thunderstorm Rain Haze | Thunderstorm Fog | Light Thunderstorm Rain Fog | Heavy Thunderstorm Rain Fog | Thunderstorm Light Rain | Thunderstorm Heavy Rain | Thunderstorm Rain Fog/Mist | Thunderstorm Light Rain Fog/Mist | Thunderstorm Heavy Rain Fog/Mist | Thunderstorm in Vicinity Fog/Mist | Thunderstorm Showers in Vicinity | Thunderstorm in Vicinity Haze | Thunderstorm Haze in Vicinity | Thunderstorm Light Rain Haze | Thunderstorm Heavy Rain Haze | Thunderstorm Fog | Thunderstorm Light Rain Fog | Thunderstorm Heavy Rain Fog | Thunderstorm Hail | Light Thunderstorm Rain Hail | Heavy Thunderstorm Rain Hail | Thunderstorm Rain Hail Fog/Mist | Light Thunderstorm Rain Hail Fog/Mist | Heavy Thunderstorm Rain Hail Fog/Hail | Thunderstorm Showers in Vicinity Hail | Light Thunderstorm Rain Hail Haze | Heavy Thunderstorm Rain Hail Haze | Thunderstorm Hail Fog | Light Thunderstorm Rain Hail Fog | Heavy Thunderstorm Rain Hail Fog | Thunderstorm Light Rain Hail | Thunderstorm Heavy Rain Hail | Thunderstorm Rain Hail Fog/Mist | Thunderstorm Light Rain Hail Fog/Mist | Thunderstorm Heavy Rain Hail Fog/Mist | Thunderstorm in Vicinity Hail | Thunderstorm in Vicinity Hail Haze | Thunderstorm Haze in Vicinity Hail | Thunderstorm Light Rain Hail Haze | Thunderstorm Heavy Rain Hail Haze | Thunderstorm Hail Fog | Thunderstorm Light Rain Hail Fog | Thunderstorm Heavy Rain Hail Fog | Thunderstorm Small Hail/Snow Pellets | Thunderstorm Rain Small Hail/Snow Pellets | Light Thunderstorm Rain Small Hail/Snow Pellets | Heavy Thunderstorm Rain Small Hail/Snow Pellets" }
const ntsraSymbol: WxSymbol = { image: "tstorm3", isPrecip: true }
const snSymbol: WxSymbol = { image: "snow4", isPrecip: true, description: "Snow | Light Snow | Heavy Snow | Snow Showers | Light Snow Showers | Heavy Snow Showers | Showers Snow | Light Showers Snow | Heavy Showers Snow | Snow Fog/Mist | Light Snow Fog/Mist | Heavy Snow Fog/Mist | Snow Showers Fog/Mist | Light Snow Showers Fog/Mist | Heavy Snow Showers Fog/Mist | Showers Snow Fog/Mist | Light Showers Snow Fog/Mist | Heavy Showers Snow Fog/Mist | Snow Fog | Light Snow Fog | Heavy Snow Fog | Snow Showers Fog | Light Snow Showers Fog | Heavy Snow Showers Fog | Showers Snow Fog | Light Showers Snow Fog | Heavy Showers Snow Fog | Showers in Vicinity Snow | Snow Showers in Vicinity | Snow Showers in Vicinity Fog/Mist | Snow Showers in Vicinity Fog | Low Drifting Snow | Blowing Snow | Snow Low Drifting Snow | Snow Blowing Snow | Light Snow Low Drifting Snow | Light Snow Blowing Snow | Light Snow Blowing Snow Fog/Mist | Heavy Snow Low Drifting Snow | Heavy Snow Blowing Snow | Thunderstorm Snow | Light Thunderstorm Snow | Heavy Thunderstorm Snow | Snow Grains | Light Snow Grains | Heavy Snow Grains | Heavy Blowing Snow | Blowing Snow in Vicinity" }
const nsnSymbol: WxSymbol = { image: "snow4", isPrecip: true }
const windSymbol: WxSymbol = { image: "cloudy1", isPrecip: false, description: "Windy | Breezy | Fair and Windy | A Few Clouds and Windy | Partly Cloudy and Windy | Mostly Cloudy and Windy | Overcast and Windy" }
const nwindSymbol: WxSymbol = { image: "cloudy1_night", isPrecip: false }
const hi_shwrsSymbol: WxSymbol = { image: "shower1", isPrecip: true, description: "Showers in Vicinity | Showers in Vicinity Fog/Mist | Showers in Vicinity Fog | Showers in Vicinity Haze" }
const hi_nshwrsSymbol: WxSymbol = { image: "shower2", isPrecip: true }
const fzraraSymbol: WxSymbol = { image: "sleet", isPrecip: true, description: "Freezing Rain Rain | Light Freezing Rain Rain | Heavy Freezing Rain Rain | Rain Freezing Rain | Light Rain Freezing Rain | Heavy Rain Freezing Rain | Freezing Drizzle Rain | Light Freezing Drizzle Rain | Heavy Freezing Drizzle Rain | Rain Freezing Drizzle | Light Rain Freezing Drizzle | Heavy Rain Freezing Drizzle" }
const hi_tsraSymbol: WxSymbol = { image: "tstorm1", isPrecip: true, description: "Thunderstorm in Vicinity | Thunderstorm in Vicinity Fog | Thunderstorm in Vicinity Haze" }
const hi_ntsraSymbol: WxSymbol = { image: "tstorm1_night", isPrecip: true }
const ra1Symbol: WxSymbol = { image: "light_rain", isPrecip: true, description: "Light Rain | Drizzle | Light Drizzle | Heavy Drizzle | Light Rain Fog/Mist | Drizzle Fog/Mist | Light Drizzle Fog/Mist | Heavy Drizzle Fog/Mist | Light Rain Fog | Drizzle Fog | Light Drizzle Fog | Heavy Drizzle Fog" }
const nraSymbol: WxSymbol = { image: "light_rain", isPrecip: true }
const raSymbol: WxSymbol = { image: "shower3", isPrecip: true, description: "Rain | Heavy Rain | Rain Fog/Mist | Heavy Rain Fog/Mist | Rain Fog | Heavy Rain Fog" }
const nsvrtsraSymbol: WxSymbol = { image: "cloudy1", isPrecip: false, description: "Funnel Cloud | Funnel Cloud in Vicinity | Tornado/Water Spout" }
const dustSymbol: WxSymbol = { image: "cloudy1", isPrecip: false, description: "Dust | Low Drifting Dust | Blowing Dust | Sand | Blowing Sand | Low Drifting Sand | Dust/Sand Whirls | Dust/Sand Whirls in Vicinity | Dust Storm | Heavy Dust Storm | Dust Storm in Vicinity | Sand Storm | Heavy Sand Storm | Sand Storm in Vicinity" }
const mistSymbol: WxSymbol = { image: "mist", isPrecip: false, description: "Haze  " }
const dunnoSymbol: WxSymbol = { image: "dunno", isPrecip: false }

const symbolLookup: Map<string, WxSymbol> = new Map([
  ["bkn", bknSymbol],
  ["nbkn", nbknSymbol],
  ["skc", skcSymbol],
  ["nskc", nskcSymbol],
  ["few", fewSymbol],
  ["nfew", nfewSymbol],
  ["sct", sctSymbol],
  ["nsct", nsctSymbol],
  ["ovc", ovcSymbol],
  ["novc", novcSymbol],
  ["fg", fgSymbol],
  ["nfg", nfgSymbol],
  ["smoke", smokeSymbol],
  ["fzra", fzraSymbol],
  ["ip", ipSymbol],
  ["mix", mixSymbol],
  ["nmix", nmixSymbol],
  ["raip", raipSymbol],
  ["rasn", rasnSymbol],
  ["nrasn", nrasnSymbol],
  ["shra", shraSymbol],
  ["scttsra", scttsraSymbol],
  ["nscttsra", nscttsraSymbol],
  ["tsra", tsraSymbol],
  ["ntsra", ntsraSymbol],
  ["sn", snSymbol],
  ["nsn", nsnSymbol],
  ["wind", windSymbol],
  ["nwind", nwindSymbol],
  ["hi_shwrs", hi_shwrsSymbol],
  ["hi_nshwrs", hi_nshwrsSymbol],
  ["fzrara", fzraraSymbol],
  ["hi_tsra", hi_tsraSymbol],
  ["hi_ntsra", hi_ntsraSymbol],
  ["ra1", ra1Symbol],
  ["nra", nraSymbol],
  ["ra", raSymbol],
  ["nra", nraSymbol],
  ["nsvrtsra", nsvrtsraSymbol],
  ["dust", dustSymbol],
  ["mist", mistSymbol],
  ["dunno", dunnoSymbol],
]);

export function symbolHasPrecip(symbol: string | undefined): boolean {
  return symbolAbbreviationToWxSymbol(symbol).isPrecip;
}

function symbolAbbreviationToWxSymbol(symbol: string | undefined): WxSymbol {

    if (symbol === undefined) {
        return dunnoSymbol;
    }

    // Remove extension
    let symbolNoExtension = symbol.replace(/\.[^/.]+$/, "");

    // Remove numbers
    let symbolNoNumbers = symbolNoExtension.replace(/[0-9]*$/, "");

    return symbolLookup.get(symbolNoNumbers) ?? dunnoSymbol;
}

function symbolToImageSource(symbol: string | undefined, size: SymbolSize): string {
    return `https://static.climbingweather.com/img/icons/wx/${ symbolAbbreviationToWxSymbol(symbol).image }-${ size }px.png`;
}

export function formatPrecipProbability(decimalPrecip: string | undefined): string {
  if (decimalPrecip === undefined) {
    return "--";
  }
  return Math.round(parseFloat(decimalPrecip)*100).toString() + "%";
}

export function formatHumidity(decimalHumidity: string | undefined): string {
  if (decimalHumidity === undefined) {
    return "--";
  }
  return Math.round(parseFloat(decimalHumidity)*100).toString() + "%";
}

export function formatCloudCover(decimalCloudCover: string | undefined): string {
    if (decimalCloudCover === undefined) {
      return "--";
    }
    return Math.round(parseFloat(decimalCloudCover)*100).toString() + "%";
}

export function formatWind(wind: string | undefined) {
    if (wind === undefined || wind.length === 0) {
        return "--";
    }
    return wind + " mph";
}

export function formatTemperature(temperature: string | undefined) {
    if (temperature === undefined || temperature.length === 0) {
        return "--";
    }
    return temperature + degreeCharacter;
}

export function formatConditions(conditions: string | undefined) {
    if (conditions === undefined || conditions.length === 0) {
        return "--";
    }
    return conditions;
}

export function formatPrecipAmount(accumulation: string | undefined,
    type: string | undefined) {
    if (accumulation === undefined || parseFloat(accumulation) === 0) {
        return "";
    }

    const t = type ?? "";

    return accumulation + " " + t;
}

export function timeToDow(unixTimestamp: number): string {
    let date = new Date(unixTimestamp * 1000);
    let today = new Date();

    if (date.getDate() === today.getDate()) {
        return "Today";
    }

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (date.getDate() === tomorrow.getDate()) {
        return "Tomorrow";
    }

    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[date.getDay()];
}

export function timeToMonthDay(unixTimestamp: number): string {
  let date = new Date(unixTimestamp * 1000);
  //let today = new Date();
  return date.toLocaleString("en-US", {month: "short"}) + " " + date.toLocaleString("en-US", {day: "numeric"})
}

export function timeToHour(unixTimestamp: number, timezone: string): string {
    let date = new Date(unixTimestamp * 1000);
    return date.toLocaleString("en-US", {hour: "numeric", hour12: true, timeZone: timezone});
  }

  export function timeToDowLocale(unixTimestamp: number, timezone: string): string {
    let date = new Date(unixTimestamp * 1000);
    return date.toLocaleString("en-US", {weekday: "short", timeZone: timezone});
  }

export enum SymbolSize {
    Big = "64",
    Small = "32"
}

export interface ForecastSymbolImageProps {
  icon: string | undefined,
  size: SymbolSize,
  testId: string
}
export function ForecastSymbolImage(props: ForecastSymbolImageProps) {
  const { icon, size, testId} = props;
  return <img src={ symbolToImageSource(icon, size)} alt="" height={size} width={size} data-testid={"symbol-" + testId} />
}

export interface ForecastHighTemperatureProps {
    temperature: string | undefined,
    testId: string
}

function ForecastHighTemperature(props: ForecastHighTemperatureProps) {
    const { temperature, testId } = props;
    return <span data-testid={"tempHigh-" + testId}>{formatTemperature(temperature)}</span>;
}

export interface ForecastLowTemperatureProps {
    temperature: string | undefined,
    testId: string
}

function ForecastLowTemperature(props: ForecastLowTemperatureProps) {
    const { temperature, testId } = props;
    return <span data-testid={"tempLow-" + testId}>{formatTemperature(temperature)}</span>;
}

interface ClimbingAreaForecastDaySmallProps {
  forecast: ForecastDataPoint
}

function ForecastHighLowHeading() {
    return <div className="desc">High/Low</div>
}
interface ForecastHighLowContentProps {
    temperatureHigh: string | undefined,
    temperatureLow: string | undefined,
    testId: string
}
function ForecastHighLowContent(props: ForecastHighLowContentProps) {
    const { temperatureHigh, temperatureLow, testId} = props;
    return (
        <div className="data">
            <ForecastHighTemperature temperature={temperatureHigh} testId={testId} /> /
            <ForecastLowTemperature temperature={temperatureLow} testId={testId} />
        </div>
    );
}

function ForecastPrecipHeading() {
    return <div className="desc">Precip (day/night)</div>
}
interface ForecastPrecipContentProps {
  day: string | undefined,
  night: string | undefined,
  testId: string
}
function ForecastPrecipContent(props: ForecastPrecipContentProps) {
  const { day, night, testId } = props;
  return (
    <div className="dataSmall">
      <ForecastPrecipDay precip={day} testId={testId} /> /
      <ForecastPrecipNight precip={night} testId={testId} />
    </div>
  )
}

interface ForecastPrecipDayProps {
  precip: string | undefined,
  testId: string
}
function ForecastPrecipDay(props: ForecastPrecipDayProps) {
  const { precip, testId } = props;
  return <span data-testid={"precipDay-" + testId}>{formatPrecipProbability(precip)}</span>
}

interface ForecastPrecipNightProps {
  precip: string | undefined,
  testId: string
}
function ForecastPrecipNight(props: ForecastPrecipNightProps) {
  const { precip, testId } = props;
  return <span data-testid={"precipDay-" + testId}>{formatPrecipProbability(precip)}</span>
}

interface ClimbingAreaForecastDaySmallIconProps {
  time: number,
  icon: string | undefined,
  testId: string
}
function ClimbingAreaForecastDaySmallIcon(props: ClimbingAreaForecastDaySmallIconProps) {
  const { time, icon, testId} = props;
  return (
    <div className="icon">
      <ForecastDay time={time} testId={testId} />
      <div className="symbol"><ForecastSymbolImage icon={icon} size={SymbolSize.Small} testId={testId} /></div>
    </div>
  );
}

interface ClimbingAreaForecastSmallDetailProps {
  high: string | undefined,
  low: string | undefined,
  precipDay: string | undefined,
  precipNight: string | undefined,
  testId: string
}
function ClimbingAreaForecastSmallDetail(props: ClimbingAreaForecastSmallDetailProps) {
  const { high, low, precipDay, precipNight, testId} = props;
  return (
    <div className="detail">
      <ForecastHighLowContent temperatureHigh={high} temperatureLow={low} testId={testId} />
      <ForecastPrecipContent day={precipDay} night={precipNight} testId={testId} />
  </div>
  )
}


export class ClimbingAreaForecastDaySmall extends React.Component<ClimbingAreaForecastDaySmallProps> {
  render() {
    let { forecast } = this.props;
    let testId = forecast.time.toString();
    return (
      <div className="forecastSide">
        <ClimbingAreaForecastDaySmallIcon time={forecast.time} icon={forecast.icon} testId={testId} />
        <ClimbingAreaForecastSmallDetail high={forecast.temperatureHigh} low={forecast.temperatureLow}
          precipDay={forecast.precipProbability} precipNight={forecast.precipProbabilityNight}
          testId={testId} />
      </div>
    );
  }
}

interface ClimbingAreaForecastLargeDetailProps {
  high: string | undefined,
  low: string | undefined,
  precipDay: string | undefined,
  precipNight: string | undefined,
  testId: string
}
function ClimbingAreaForecastLargeDetail(props: ClimbingAreaForecastLargeDetailProps) {
  const { high, low, precipDay, precipNight, testId} = props;
  return (
    <div className="detail">
      <ForecastHighLowHeading />
      <ForecastHighLowContent temperatureHigh={high} temperatureLow={low} testId={testId} />
      <ForecastPrecipHeading />
      <ForecastPrecipContent day={precipDay} night={precipNight} testId={testId} />
  </div>
  )
}

interface ClimbingAreaForecastDayLargeIconProps {
  time: number,
  icon: string | undefined,
  testId: string
}
function ClimbingAreaForecastDayLargeIcon(props: ClimbingAreaForecastDayLargeIconProps) {
  const { time, icon, testId} = props;
  return (
    <div className="icon">
      <ForecastDay time={time} testId={testId} />
      <div className="symbol"><ForecastSymbolImage icon={icon} size={SymbolSize.Big} testId={testId} /></div>
    </div>
  );
}

interface ForecastDayProps {
  time: number,
  testId: string
}
function ForecastDay(props: ForecastDayProps) {
  const { time, testId } = props;
  return <div className="day" test-dataid={"day-" + testId}>{ timeToDow(time) }</div>
}

interface ClimbingAreaForecastDayLargeProps {
  forecast: ForecastDataPoint
}
export class ClimbingAreaForecastDayLarge extends React.Component<ClimbingAreaForecastDayLargeProps> {
  render() {
    let { forecast } = this.props;
    let testId = forecast.time.toString();
    return (
      <div className="forecast">
        <ClimbingAreaForecastDayLargeIcon time={forecast.time} icon={forecast.icon} testId={testId} />
        <ClimbingAreaForecastLargeDetail high={forecast.temperatureHigh} low={forecast.temperatureLow}
          precipDay={forecast.precipProbability} precipNight={forecast.precipProbabilityNight}
          testId={testId} />
      </div>
    );
  }
}

interface ClimbingAreaForecast3DayProps {
  forecasts: ForecastDataPoint[] | undefined
}

export function ClimbingAreaForecast3Day(props: ClimbingAreaForecast3DayProps) {
  if (props.forecasts === undefined) {
    return <div>none</div>
  }
  const forecasts = props.forecasts!
  return (
    <React.Fragment>
      <ClimbingAreaForecastDayLarge forecast={forecasts[0]} />
      <ClimbingAreaForecastDaySmall forecast={forecasts[1]} />
      <ClimbingAreaForecastDaySmall forecast={forecasts[2]} />
    </React.Fragment>
  )

}
