import React from 'react';

interface FooterSectionProps { }
function FooterSection(props: FooterSectionProps) {
  return (
    <div className="footerwrap">
      <div className="footer">
        <div className="slogan">Keeping you high and dry since 2005</div>
        <div className="copyright">&copy; ClimbingWeather.com. All rights reserved.</div>
        <div className="footerLinks">
          Twitter: @climbingweather
        </div>
      </div>
    </div>
  );
}

export default FooterSection;