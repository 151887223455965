import React from 'react';
import { ClimbingArea, AdminArea } from '../types';
import { AdminAreaLink, ClimbingAreaLink } from './common';

interface AdminAreaHeadingProps { adminArea: AdminArea }
const AdminAreaHeading = (props: AdminAreaHeadingProps) => {
     return (
         <h2 style={{ marginBottom: "3px" }}>
             {props.adminArea.name}
         </h2>
     )
 };

 interface StateAreasProps { areas: ClimbingArea[] | undefined }
 const StateAreas = (props: StateAreasProps) => {

    if (props.areas === undefined) {
        return <div>none</div>;
    }

    const areas = props.areas!
     return (
         <React.Fragment>
         { areas.map( (area) => (
            <div key={area.areaId}><ClimbingAreaLink area={area}></ClimbingAreaLink></div>
         ))}
         </React.Fragment>
     )
 };

 interface ClimbingAreaLinksProps {
    areas: ClimbingArea[] | undefined
    title?: string
    areaCount?: string
  }
  
  const ClimbingAreaLinks = (props: ClimbingAreaLinksProps) => {
      //let { areas, title, areaCount } = this.props;
      let { areas } = props;
  
      if (areas === undefined) {
        return <div>none</div>
      }
  
      return (
        <React.Fragment>
          {areas.map((area, i) => (
            <React.Fragment key={area.areaId}>
              {i > 0 && ", "}
              <ClimbingAreaLink area={area} />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    };

 interface AdminAreaListProps {
     adminArea: AdminArea
 }

 const AdminAreaList = (props: AdminAreaListProps) => {
    const adminArea = props.adminArea;
    return (
        <div className="frontState" key={adminArea.name}>
          <div className="stateName">
            {adminArea.name ? (
              <AdminAreaLink code={adminArea.code} className="frontState">{adminArea.name}</AdminAreaLink>
            ) : (
                <React.Fragment>{adminArea.name}</React.Fragment>
              )}
            {adminArea.areaCount && (
              <span className="areaCount">
                {" "}
                ({adminArea.areaCount} climbing areas)
              </span>
            )}
          </div>
          <div className="areas">
            <ClimbingAreaLinks areas={adminArea.areas} />
            {adminArea.name && (
              <React.Fragment>
                &nbsp;-&nbsp;<AdminAreaLink code={adminArea.code}>[View all {adminArea.name} climbing areas]</AdminAreaLink>
              </React.Fragment>
            )}
          </div>
        </div>
      );
 };

 interface AdminAreaProps {
    adminArea: AdminArea,
    format?: string
}

const AdminAreaSection = (props: AdminAreaProps) => {
    const adminArea = props.adminArea;

    if (props.format === 'list') {
        return <AdminAreaList adminArea={adminArea} />
    }

    return (
        <div className="stateAreaSection" key={adminArea.name} id={adminArea.code}>
            <AdminAreaHeading adminArea={adminArea}></AdminAreaHeading>
            <StateAreas areas={adminArea.areas}></StateAreas>
        </div>
    );
};



export default AdminAreaSection;