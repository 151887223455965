import React, { ChangeEvent, useCallback, useState, useRef, RefObject } from 'react';
import { useLocation } from 'react-router-dom';

import { ClimbingArea } from "../types";
import { ClimbingAreaLink } from "./common";
import fetchAreas from "../api/areas";
import debounce from 'lodash.debounce';

const MIN_SEARCH_LENGTH = 2;

enum SearchStatus {
    NoSearchStr,
    TooShort,
    FetchSent,
    ReceivedError,
    ReceivedEmpty,
    ReceivedAreas,
    AreaClicked,
    NoFocus
  }

function Search() {

    const [error, setError] = useState<string | null>(null);
    const [areas, setAreas] = useState<ClimbingArea[] | null>(null);
    const [searchStr, setSearchStr] = useState<string>("");
    const [loadedSearchStr, setLoadedSearchStr] = useState<string | null>(null);
    const [searchStatus, setSearchStatus] = useState<SearchStatus>(SearchStatus.NoSearchStr)
  
    const inputRef = useRef<HTMLInputElement>(null)
  
    const location = useLocation();
  
    React.useEffect(() => {
      setSearchStatus(SearchStatus.NoFocus);
      inputRef.current?.blur()
    }, [location]);
  
    
  
    // Get search status from the state
    const searchStatusFromState = (): SearchStatus => {
  
      // No need to change these
      if (searchStatus in [SearchStatus.FetchSent, SearchStatus.ReceivedError, SearchStatus.ReceivedAreas, SearchStatus.ReceivedEmpty]) {
        return searchStatus;
      }
  
      // No search string
      if (searchStr.length === 0) {
        return SearchStatus.NoSearchStr
      }
  
      // Search string too short
      if (searchStr.length < MIN_SEARCH_LENGTH) {
        return SearchStatus.TooShort
      }
  
      // Search state was previously no focus but we are getting focus
      if (searchStatus === SearchStatus.NoFocus) {
        if (error !== null) {
          return SearchStatus.ReceivedError
        } else if (areas?.length === 0) {
          return SearchStatus.ReceivedEmpty
        } else {
          return SearchStatus.ReceivedAreas
        }
      }
  
      return SearchStatus.NoFocus
  
    }
    // On focus, display the search results if we already have some typed text
    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setSearchStatus(searchStatusFromState());
    }
  
    const handleInputBlur = () => {
      setSearchStatus(SearchStatus.NoFocus);
    }
  
    const handleKeyboardEvent = (e: ChangeEvent<HTMLInputElement>): void => {
      setSearchStr(e.target.value);
      debouncedFetch(e.target.value);
    }
  
    const doFetch = (str: string) => {
  
      // Do not fetch if the search status is initial, don't need to show error message either
      if (str.length === 0) {
        setSearchStatus(SearchStatus.NoSearchStr);
        return;
      }
  
      if (str.length < MIN_SEARCH_LENGTH) {
        setSearchStatus(SearchStatus.TooShort); 
        return;
      }
  
      // Do not re-fetch if the string has not changed
      if (str === loadedSearchStr) {
        return;
      }
  
      setLoadedSearchStr(null);
      setSearchStatus(SearchStatus.FetchSent);
      fetchAreas(str)
      .then(
        (areas: ClimbingArea[]) => {
          setAreas(areas);
          setLoadedSearchStr(str);
          console.log(areas);
          setSearchStatus(areas.length === 0 ? SearchStatus.ReceivedEmpty : SearchStatus.ReceivedAreas)
        },
        (error) => {
          setLoadedSearchStr(str);
          setError(error);
        }
      )
    };
  
    const debouncedFetch = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
      debounce(str => doFetch(str), 500
      ), []
    );
  
    const areaMouseDownHandler = (e: React.MouseEvent) => {
      e.preventDefault();
    }
  
    return (
      <div id="searchBar">
        <div id="searchBarInner">
          <SearchInput
            focusHandler={handleInputFocus}
            blurHandler={handleInputBlur}
            changeHandler={handleKeyboardEvent}
            inputRef={inputRef} />
          <SearchResults searchStr={searchStr}
            areas={areas} 
            error={error}
            searchStatus={searchStatus}
            loadedSearchStr={loadedSearchStr}
            areaMouseDownHandler={areaMouseDownHandler}/>
        </div>
      </div>
    );
  }
  
  interface SearchInputProps { 
    focusHandler: (event: React.FocusEvent<HTMLInputElement>) => void,
    blurHandler: (event: React.FocusEvent<HTMLInputElement>) => void,
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
    inputRef: RefObject<HTMLInputElement>
  }
  class SearchInput extends React.Component<SearchInputProps> {
  
    render() {
      return <input type="text"
        id="searchInput" placeholder="Search Climbing Areas" 
        onFocus={this.props.focusHandler} 
        onBlur={this.props.blurHandler} 
        onChange={this.props.changeHandler}
        ref={this.props.inputRef} />
    }
  }
  
  interface SearchResultsProps { 
    searchStr: String, 
    areas: ClimbingArea[] | null, 
    error: string | null, 
    searchStatus: SearchStatus, 
    loadedSearchStr: string | null,
    areaMouseDownHandler: (e: React.MouseEvent) => void
  }
  function SearchResults(props: SearchResultsProps) {
  
    // States
    // Initial: help text
    // Text entered but no minimum length: no change
    // Text entered and fetch sent: loading
    // Fetch received with error: show error
    // Fetch received with no areas: no areas found
    // Fetch received with areas: area links
    var innerContent = <div></div>;
    var hidden = false;
  
    switch (props.searchStatus) {
      case SearchStatus.NoSearchStr: {
        hidden = true;
        break
      }
      case SearchStatus.FetchSent: {
        innerContent = <div>Loading!</div>;
        break;
      }
      case SearchStatus.ReceivedError: {
        innerContent = <div>An error occurred!</div>;
        break;
      }
      case SearchStatus.ReceivedEmpty: {
        innerContent = <div>No areas found!</div>;
        break;
      }
      case SearchStatus.ReceivedAreas: {
        innerContent = <div><SearchResultsAreas areas={props.areas} mouseDownHandler={props.areaMouseDownHandler} /></div>;
        break;
      }
      case SearchStatus.TooShort: {
        innerContent = <div>Enter at least 2 characters</div>;
        break;
      }
      case SearchStatus.AreaClicked: {
        break
      }
      case SearchStatus.NoFocus: {
        hidden = true;
        break
      }
    }
  
    return <div id="searchResults" className={hidden ? 'hidden' : ''}>{innerContent}</div>
  }
  
  interface SearchResultsAreasProps { 
    areas: ClimbingArea[] | null, 
    mouseDownHandler: React.MouseEventHandler<HTMLAnchorElement>
  }
  function SearchResultsAreas(props: SearchResultsAreasProps) {
  
    return (
      <div id="searchResultsAreas">
      { props.areas?.map( (area, i) => (
          <div className="searchResultItem" key={"searchResultArea-" + area.areaId}><ClimbingAreaLink area={area} onMouseDown={props.mouseDownHandler}/></div>
      )) ?? <div>None</div>}
      { props.areas === null || props.areas.length === 0 ? <div>No areas found</div> : null }
      </div>
    );
  }

  export default Search;