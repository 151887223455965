import { ClimbingArea } from "../types";
import { apiDomain } from "../utils/climbingAreaUtils";

async function fetchAreas(srch: string = ""): Promise<ClimbingArea[]> {
    const baseUrl = new URL("https://" + apiDomain());
    const url = new URL('/area/list/' + srch, baseUrl);

    const response = await fetch(url.toString());
    const areas: ClimbingArea[] = await response.json();
    if (response.ok && areas) {
        return areas;
    } else {
        return Promise.reject(new Error(response.statusText));
    }
}

export default fetchAreas;