import React from "react";
import { ClimbingArea, ForecastDataPoint } from "../types";
import { ForecastSymbolImage, SymbolSize, timeToDow, 
    timeToMonthDay, 
    formatTemperature, formatConditions, symbolHasPrecip } from "./climbingAreaForecast";

/// Daily area
interface DailyProps { area: ClimbingArea }
const Daily = (props: DailyProps) => {
    const area = props.area;
    return (
        <div id="areaForecast">
            <DailyFlex area={area} />
        </div>
    );
};

interface DailyFlexProps { area: ClimbingArea }
const DailyFlex = (props: DailyFlexProps) => {
    return (
        <div className="daily-row__">
            { props.area.forecast?.daily.data.slice(0, 8).map( (forecast, i) => (
                <DailyFlexRow forecast={forecast} key={forecast.time} />
            )) ?? <div className="daily-row__NoForecast">No forecast available</div>}
        </div>
    )
};

interface DailyFlexRowProps { forecast: ForecastDataPoint }
const DailyFlexRow = (props: DailyFlexRowProps) => {

    const { forecast } = props;
    const testId = String(forecast.time);
    
    return (
        <div className="daily-row">
            <div className="daily-row__day">
                <div className="daily-row__day__dow">{timeToDow(forecast.time)}</div>
                <div className="daily-row__day__date">{timeToMonthDay(forecast.time)}</div>
            </div>
            <div className="daily-row__sp">
                <div className="daily-row__sp__precip">{formatPrecip(forecast.precipProbability, symbolHasPrecip(forecast.icon))}</div>
                <div className="daily-row__sp__symbol"><ForecastSymbolImage icon={forecast.icon} size={SymbolSize.Small} testId={testId} /></div>
                <div className="daily-row__sp__accum">{formatPrecipAccum(forecast.precipAccumulation, symbolHasPrecip(forecast.icon))}</div>
            </div>
            <div className="daily-row__wind">
                <div className="daily-row__wind__sustained">{formatWind(forecast.windSpeed)}</div>
            </div>
            <div className="daily-row__temp">
                <div className="daily-row__temp__hl">
                    <div className="daily-row__temp__hl__high">{formatTemperature(forecast.temperatureHigh)}</div>
                    <div className="daily-row__temp__hl__low">{formatTemperature(forecast.temperatureLow)}</div>
                </div>
            </div>
            <div className="daily-row__details">
                <div className="daily-row__details__conditions">{formatConditions(forecast.summary)}</div>
            </div>
        </div>
    )
}

/*
/// Daily table
interface DailyTableProps { area: ClimbingArea }
const DailyTable = (props: DailyTableProps) => {
    const area = props.area;
    return (
        <table className="forecast">
        <tbody>
        { area.forecast?.daily.data.map( (forecast, i) => (
            <React.Fragment key={forecast.time}>
                <DailyTableRow area={area} index={i} />
            </React.Fragment>
        )) ?? <div>None</div>}
        </tbody>
        </table>
    )
};

/// Daily table row
interface DailyTableRowProps {
    area: ClimbingArea,
    index: number
}
const DailyTableRow = (props: DailyTableRowProps) => {
    const { area, index } = props;

    if (area.forecast === undefined) {
        return <tr><td>None</td></tr>
    }

    const forecast = area.forecast!
    
    const dp = forecast.daily.data[index];
    const rowClasses = ["odd", "even"];
    return (
        <tr className={rowClasses[index % 2]}>
            <DateCell hourlyLink="test" time={dp.time} timezone={forecast.timezone} />
            <SymbolCell icon={dp.icon} precip={dp.precipProbability} testId={String(dp.time)}/>
            <TempCell high={dp.temperatureHigh} low={dp.temperatureLow} />
            <td className="dailyConditions">{formatConditions(dp.summary)}</td>
        </tr>
    )
};

/// Date cell
interface DateCellProps { hourlyLink: string, time: number, timezone: string }
const DateCell = (props: DateCellProps) => {
    const time = props.time;
    return (
        <td className="day_date">
            <div className="day">{timeToDow(time)}</div>
            <div className="date">{timeToMonthDay(time)}</div>
        </td>
    )
};

interface SymbolCellProps { 
    icon: string | undefined,
    testId: string,
    precip: string | undefined
}
const SymbolCell = (props: SymbolCellProps) => {
    const { icon, testId, precip } = props;
    return (
        <td>
            <div className="dailySymbolPrecip">
                <ForecastSymbol icon={icon} testId={testId} />
                <PrecipBySymbol precip={precip} hasPrecip={symbolHasPrecip(icon)} />
            </div>
        </td>
    )
};

interface ForecastSymbolProps {
    icon: string | undefined,
    testId: string
}

const ForecastSymbol = (props: ForecastSymbolProps) => {
    const { icon, testId} = props;
    return (
        <div className="dailySymbol"><ForecastSymbolImage icon={icon} size={SymbolSize.Small} testId={testId} /></div>
    );
};

interface PrecipBySymbolProps { 
    precip: string | undefined
    hasPrecip: boolean
}
const PrecipBySymbol = (props: PrecipBySymbolProps) => {
    const { precip, hasPrecip } = props;
    if (precip === undefined) {
        return null;
    }

    const precipPercentage = Math.round(parseFloat(precip)*100)
    if (!hasPrecip && precipPercentage < 30) {
        return null;
    }
    
    return (
        <div className="precipBySymbol">{precipPercentage.toString() + "%"}</div>
    );
};
*/

const formatPrecip = (precip: string | undefined, hasPrecip: boolean): string | null => {

    if (precip === undefined) {
        return null;
    }

    const precipPercentage = Math.round(parseFloat(precip)*100)
    if (!hasPrecip && precipPercentage < 30) {
        return null;
    }
    
    return precipPercentage.toString() + "%";

}

const formatPrecipAccum = (precip: string | undefined, hasPrecip: boolean): string | null => {

    if (precip === undefined || !hasPrecip) {
        return null;
    }

    return precip;

}

const formatWind = (windSpeed: string | undefined): string | null => {
    if (windSpeed === undefined) {
        return null;
    }

    return windSpeed + " mph"
}
/*

interface TempCellProps { high: string | undefined, low: string | undefined }
const TempCell = (props: TempCellProps) => {
    const { high, low } = props;

    return (
        <td className="temp">
            <div className="tempInner">
                <div className="tempHigh">{formatTemperature(high)}</div>
                <div className="tempLow">{formatTemperature(low)}</div>
            </div>
    </td>
    )
};
*/

export default Daily;