import React, { useEffect, useState } from 'react';
import { ClimbingArea } from '../types';
import { ClimbingAreaForecast3Day } from './climbingAreaForecast';
import { ClimbingAreaLink } from './common';
import fetchFeaturedArea from "../api/featuredArea";

const FeaturedArea = () => {

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [result, setResult] = useState<ClimbingArea | null>(null);

    useEffect(() => {
        fetchFeaturedArea()
        .then(
          (result) => {
            setIsLoaded(true);
            setResult(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }, []);

    if (error) {
      return <div>Error: (error.message)</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else if (result === null) {
      return <div>none</div>
    } else {
      const area = result!

      return (
        <React.Fragment>
          <div style={{ marginBottom: "10px" }}>
            <strong>
              <a href="/{area.adminArea}" style={{ color: "#000" }}>
                {area.adminAreaName}
              </a>
            </strong>
            &nbsp;|&nbsp;
            <ClimbingAreaLink area={area}></ClimbingAreaLink>
          </div>
          {area.forecast?.daily.data.length === 3 
            ? <ClimbingAreaForecast3Day forecasts={area.forecast?.daily.data} />
            : <span>Forecast not available</span>}
        </React.Fragment>
      );
    }
};

export default FeaturedArea;